<template>
  <b-card-code
    title="NftManagement"
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <!-- {{this.$store.state.nfts.nftData}} -->
        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
 
    >
      <!-- <template #cell(Thumbnail)="data">
        <b-avatar :src="data.value" />
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template> -->

      <template #cell(id)="data">
        <router-link :to="`/nft-detail/${data.value}`">
        <b-badge :variant="light-success">
          상세보기
        </b-badge>
        </router-link>&nbsp;
        <router-link :to="`/nft-update/${data.value}`">
        <b-badge :variant="light-danger">
          수정
        </b-badge>
        </router-link>
      </template>


    </b-table>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>


  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
} from 'bootstrap-vue'

import store from '@/store/index'
import { ref as imgRef,getDownloadURL } from "firebase/storage";
import { ref } from '@vue/composition-api'
import { storage } from "../firebase"
export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  data() {
    return {
      perPage: 50,
      pageOptions: [10, 20, 50,100,500,1000],
      totalRows: 1,
      currentPage: 2,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // {
        //   key: 'thumbnail', label: '썸네일',sortable: false
        // },
        // {
        //   key: 'NameKor', label: '프젝명(한글))',sortable: true
        // },
        {
          key: 'NameEn', label: '프젝명(영문)',sortable: true
        },
        {
          key: 'Chain', label: '체인',sortable: true
        },
        {
          key: 'FloorPrice', label: '오픈시 바닥가',sortable: true
        },
       
        {
          key: 'Status', label: '출시현황',sortable: true
        },
        {
          key: 'Writer', label: '작성자',sortable: true
        },
        {
          key: 'id', label: '추가메뉴',sortable: false
        }, 
      ],
      /* eslint-disable global-require */
      items: store.state.nfts.nftData,
      /* eslint-disable global-require */
      status: [{
        'Upcomming':'출시전','Published':'출시완료'
      },
      {
        // 1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
        'Upcomming':'light-primary','Published':'light-success'
      }],
      
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getPhoto(value){

      // console.log(value)
      // const starsRef = ref(storage, value);
      // getDownloadURL(starsRef)
      // .then((url) => {
      //   console.log(url)
      //   return url
      // })
      return value
      
    },
  },

}
</script>
